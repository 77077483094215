<template>
    <div class="container my-4">
        <div
            v-if="success.length"
            class="alert alert-sm alert-light d-flex align-items-center show fade my-4 rounded"
            role="alert"
        >
            {{ success }}
            <button type="button" @click="success = ''" class="ml-auto close">
                <span>&times;</span>
            </button>
        </div>
        <div class="card">
            <div class="card-header">Quick scan</div>
            <div class="card-body">
                Scan an item's barcode to check its status
                <br />
                <small class="text-danger">{{ error }}</small>
                <form id="find-item" class="mt-4" @submit.prevent="getItem">
                    <div class="input-group mb-3">
                        <input
                            ref="uuidInput"
                            type="text"
                            class="form-control form-control-sm"
                            required
                            v-model="uuid"
                        />
                        <div class="input-group-append">
                            <button class="py-1 btn btn-light" type="submit">Search</button>
                        </div>
                    </div>
                </form>
                <div v-if="item && !error.length">
                    <hr />
                    <div class="d-flex align-items-center mb-3">
                        <h6 class="mb-0">{{ item.product_name }}</h6>
                        <small class="ml-2 text-secondary">({{ item.stock_qty }} in stock)</small>
                    </div>
                    <div class="d-flex align-items-center">
                        <div>{{ updateItemText }}</div>
                        <div class="d-flex ml-auto">
                            <button
                                @click="cancel"
                                class="ml-auto mr-2 btn btn-sm btn-light"
                            >Cancel</button>
                            <SubmitButton
                                @click-submit="updateItem"
                                :text="btnData.text"
                                :btnClass="btnData.class"
                                :btnDisabled="processing"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import validateUUID from 'uuid-validate';
import { getItem, updateItem } from '../../services/ItemService';
import SubmitButton from '../../components/shared/SubmitButton.vue';

export default {
    name: 'QuickScan',
    components: {
        SubmitButton
    },
    data() {
        return {
            item: null,
            uuid: '',
            error: '',
            success: '',
            processing: false
        };
    },
    computed: {
        btnData() {
            return {
                text: this.item.in_stock ? 'Remove' : 'Add',
                class: this.item.in_stock ? 'btn-danger' : 'btn-primary'
            };
        },
        updateItemText() {
            return this.item.in_stock
                ? 'Remove the scanned item from the inventory?'
                : 'Add the scanned item to the inventory?';
        }
    },
    mounted() {
        this.$refs.uuidInput.focus();
    },
    methods: {
        async getItem() {
            try {
                if (!this.validateUUID()) return;
                const response = await getItem(this.uuid);
                this.item = response.data;
                this.error = '';
            } catch (error) {
                this.error = 'Scanned item not found';
            }
            this.uuid = '';
        },
        async updateItem() {
            try {
                this.processing = true;
                const response = await updateItem(this.item.id, { in_stock: !this.item.in_stock });
                const text = this.item.in_stock ? 'removed from' : 'added to';
                this.success = `Scanned item for ${this.item.product_name} has been ${text} the inventory`;
            } catch (error) {
                console.log(error);
            }
            this.item = null;
            this.processing = false;
        },
        cancel() {
            this.item = null;
            this.error = '';
            this.uuid = '';
        },
        validateUUID() {
            if (!validateUUID(this.uuid.toLowerCase())) {
                this.error = 'Scanned data does not match UUID format';
                return this.$refs.uuidInput.focus();
            }
            return true;
        }
    }
};
</script>

<style scoped>
#find-item .btn-light {
    border: 1px solid #becad6;
}
</style>